import clsx from 'clsx'
import { LinkProps } from 'next/link'
import { forwardRef, HtmlHTMLAttributes, ReactNode } from 'react'

import { LinkTransition } from '../LinkTransition'

export type ILinkProps = HtmlHTMLAttributes<HTMLAnchorElement> &
  LinkProps & {
    href: string
    children?: ReactNode
    className?: string
    locale?: string
    onFocus?: HtmlHTMLAttributes<HTMLAnchorElement>['onFocus']
    onBlur?: HtmlHTMLAttributes<HTMLAnchorElement>['onBlur']
    tabIndex?: HtmlHTMLAttributes<HTMLAnchorElement>['tabIndex']
    /** outbound links always open in a new tab */
    newTab?: boolean
    target?: string
    scroll?: boolean
    title?: HtmlHTMLAttributes<HTMLAnchorElement>['title']
    onMouseEnter?: HtmlHTMLAttributes<HTMLAnchorElement>['onMouseEnter']
    onMouseLeave?: HtmlHTMLAttributes<HTMLAnchorElement>['onMouseLeave']
    onPointerEnter?: HtmlHTMLAttributes<HTMLAnchorElement>['onPointerEnter']
    onPointerLeave?: HtmlHTMLAttributes<HTMLAnchorElement>['onPointerLeave']
    style?: HtmlHTMLAttributes<HTMLAnchorElement>['style']
    'aria-label'?: HtmlHTMLAttributes<HTMLAnchorElement>['aria-label']
  }

export const isInternalLink = (href: string) => /^\/(?!\/)/.test(href)

export const Link = forwardRef<HTMLAnchorElement, ILinkProps>(
  (
    {
      href,
      children,
      className,
      locale,
      tabIndex,
      newTab,
      scroll,
      style,
      ...props
    },
    ref,
  ) => {
    // nextjs has optimization for internal links
    const isInternal = isInternalLink(href)

    const commonProps = {
      className: clsx('cursor-pointer', className),
      style,
      tabIndex,
      href,
    }

    return isInternal ? (
      <LinkTransition
        ref={ref}
        locale={locale}
        scroll={scroll}
        target={newTab ? '_blank' : undefined}
        {...props}
        {...commonProps}
      >
        {children}
      </LinkTransition>
    ) : (
      <a
        ref={ref}
        target="_blank"
        rel="noreferrer"
        aria-label={typeof children === 'string' ? children : ''}
        {...props}
        {...commonProps}
      >
        {children}
      </a>
    )
  },
)

export default Link
