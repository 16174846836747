import clsx from 'clsx'
import { createElement, ElementType, ReactNode } from 'react'

// import { Hyphen } from '../Hyphen'

export interface ITextProps {
  /**
   * Type of element to render.
   */
  as?: ElementType
  /**
   * Predefined text appearance.
   */
  variant?: keyof typeof variants
  children?: ReactNode
  noHyphen?: boolean
  className?: string
  id?: string
}

const sizes: Record<keyof typeof variants, string> = /*tw*/ {
  /**
   * Define mobile and desktop text sizes
   */
  h1: 'text-heading-1-m md:text-heading-1',
  h2: 'text-heading-2-m md:text-heading-2',
  h3: 'text-heading-3-m md:text-heading-3 antialiased',
  h4: 'text-medium-feature-m md:text-heading-1-m',
  p: 'text-body',
  pSmall: 'text-body-m',
  strong: 'text-body-m md:text-body font-bold',
  'tiny-feature': 'text-medium-feature-m md:text-tiny-feature',
  // 'big-feature': 'text-big-feature-m md:text-big-feature',
  'medium-feature': 'text-medium-feature-m md:text-medium-feature',
  'medium-feature-m': 'text-medium-feature-m',
  // 'small-feature': 'text-small-feature-m md:text-small-feature',
  // 'smaller-feature': 'text-smaller-feature-m md:text-smaller-feature',
  'label-small': 'text-label-small-m md:text-label-small',
  'label-smaller': 'text-label-small-m',
  'label-small-bold': 'text-label-small-m md:text-label-small',
  'label-medium': 'text-label-medium-m md:text-label-medium',
  'label-large': 'text-label-large-m md:text-label-large',
  button: 'text-button md:text-button',
  cardTitle: 'text-smaller-feature',
  cardText: 'body-m',
  cardLinkTitle: 'text-label-medium-m md:text-label-medium',
  cardLinkText: 'text-label-small-m',
  menu: 'text-nav-menu',
  'title-consent': 'text-title-consent-m md:text-title-consent',
  'label-consent': 'text-label-consent-m md:text-label-consent',
}

export type GetTextStylesProps = Pick<ITextProps, 'variant'>

const variants = /*tw*/ {
  h1: 'text-trueBlue font-tobias',
  h2: 'text-trueBlue font-tobias',
  h3: 'text-trueBlue font-tobias',
  h4: 'text-trueBlue font-tobias',
  p: 'text-current font-matter',
  pSmall: 'text-current font-matter',
  strong: 'text-current font-matter',
  'medium-feature-m': 'text-trueBlue font-tobias',
  'tiny-feature': 'text-current font-tobias text-trueBlue',
  // 'big-feature': 'text-trueBlue',
  'medium-feature': 'text-trueBlue',
  // 'small-feature': 'text-trueBlue',
  // 'smaller-feature': 'text-trueBlue',
  'label-small': '',
  'label-smaller': 'font-medium',
  'label-small-bold': 'font-medium',
  'label-medium': '',
  'label-large': '',
  'title-consent': 'font-matter font-semibold',
  'label-consent': 'font-matter',
  button: '',
  cardTitle: 'font-tobias',
  cardText: 'font-matter text-current font-light',
  cardLinkTitle: 'font-matter font-medium',
  cardLinkText: 'font-matter font-light',
  menu: 'font-matter font-medium antialiased',
}

export const getTextStyles = (
  { variant = 'p' }: GetTextStylesProps,
  className = '',
) => clsx(sizes[variant], variants[variant], className)

export const Text = ({
  as = 'p',
  className,
  variant,
  // eslint-disable-next-line unused-imports/no-unused-vars
  noHyphen,
  ...restProps
}: ITextProps) => {
  // TODO: disabling this or now...
  // if (!noHyphen && typeof restProps.children === 'string') {
  //   restProps.children = <Hyphen>{restProps.children}</Hyphen>
  // }

  return createElement(as, {
    className: getTextStyles({ variant }, className),
    ...restProps,
  })
}

export default Text
