import {
  classed,
  ComponentProps,
  DerivedComponentType,
} from '@tw-classed/react'
import {
  ArrowRight,
  ArrowUpRight,
  Menu,
  QrCode,
  Search,
  UserIcon,
} from 'lucide-react'
import { forwardRef, ReactNode } from 'react'

import { Text } from '../Text'

import { cn } from '@/lib/utils'

const BaseButton = classed('button')

export type TButtonProps = {
  variant?: 'yellow' | 'white' | 'ghost' | 'link' | 'white-new'
  size?: 'small' | 'large' | 'none'
  children: ReactNode
  icon?:
    | 'menu'
    | 'arrow-up-right'
    | 'search'
    | 'qr-code'
    | 'arrow-right'
    | 'user'
  disabled?: boolean
} & ComponentProps<typeof BaseButton>

export const Button = forwardRef<HTMLButtonElement, TButtonProps>(
  (
    {
      variant = 'yellow',
      disabled = false,
      children,
      size = 'small',
      icon,
      className,
      ...rest
    },
    ref,
  ) => {
    const buttonStyles = {
      variant: {
        yellow: /** @tw */ 'bg-yellow text-dark hover:bg-baby-blue',
        white:
          /** @tw */ 'bg-white text-dark hover:bg-gray-2 hover:ring-1 hover:ring-blue hover:text-blue',
        'white-new':
          /** @tw */ 'bg-white inline-block text-dark hover:!shadow-bluePill !shadow-pill transition duration-300',
        ghost:
          /** @tw */ 'bg-transparent ring-1 ring-gray-2 text-dark hover:bg-gray-2 hover:ring-1 hover:ring-blue hover:text-blue',
        link: /** @tw */ 'text-dark font-medium',
      },
      size: {
        none: /** @tw */ 'rounded-none h-auto px-0 py-0',
        small:
          /** @tw */ 'h-[36px] px-6 rounded-80px shadow-none shadow-base/0 hover:shadow-base',
        large:
          /** @tw */ 'h-[44px] px-4 rounded-8px shadow-none shadow-base/0 hover:shadow-base',
      },
    }

    let Cmp = null

    // for now we have to add the icons here we want to be available for button
    switch (icon) {
      case 'menu':
        Cmp = Menu
        break
      case 'arrow-up-right':
        Cmp = ArrowUpRight
        break
      case 'search':
        Cmp = Search
        break
      case 'qr-code':
        Cmp = QrCode
        break
      case 'arrow-right':
        Cmp = ArrowRight
        break
      case 'user':
        Cmp = UserIcon
        break
      default:
        break
    }

    return (
      <BaseButton
        ref={ref}
        className={cn(
          'focusable inline-flex items-center font-bold justify-center whitespace-nowrap rounded-32px transition-all duration-500 gap-2 outline-none',
          variant &&
            buttonStyles.variant[variant as keyof typeof buttonStyles.variant],
          size && buttonStyles.size[size as keyof typeof buttonStyles.size],
          {
            'pointer-events-none opacity-50': disabled,
          },
          className,
        )}
        disabled={disabled}
        {...rest}
      >
        {typeof children === 'string' ? (
          <Text className="antialiased" as="span" variant="button">
            {children}
          </Text>
        ) : (
          children
        )}
        {Cmp && <Cmp width={16} height={16} />}
      </BaseButton>
    )
  },
) as DerivedComponentType<typeof BaseButton, TButtonProps>
