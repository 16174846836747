import { type ClassValue, clsx } from 'clsx'
import { ResolvingMetadata } from 'next'
import { twMerge } from 'tailwind-merge'

import { SeoResponseType } from '@/sanity/service/seo'
import {
  InternationalizedArrayString,
  TranslatableString,
} from '@/types/sanity.types'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

// check for temporary setup where sub pages and misc other things are not visible or hardcoded
export const isTempSetup = process.env.NEXT_PUBLIC_TEMPORARY_SETUP === 'true'

export const kebabToPascal = (kebabCase: string): string => {
  const words = kebabCase.split('-')

  const pascalCaseWords = words.map((word, index) => {
    if (index === 0) {
      return word.charAt(0).toUpperCase() + word.slice(1)
    } else {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    }
  })

  return pascalCaseWords.join('')
}

type GroupItem = {
  group?: string
}

export const itemsByGroup = <T extends GroupItem>(
  items: T[],
  sorted = false,
) => {
  const groupedItems: Record<string, T[]> = {}

  items.forEach((item) => {
    if (item.group) {
      if (!groupedItems[item.group]) {
        groupedItems[item.group] = []
      }

      groupedItems[item.group].push(item)
    } else {
      if (!groupedItems['DEFAULT']) {
        groupedItems['DEFAULT'] = []
      }

      groupedItems['DEFAULT'].push(item)
    }
  })

  // Sort the keys in alphabetical order
  const sortedKeys = Object.keys(groupedItems).sort((a, b) => {
    if (a === 'DEFAULT') return 1
    if (b === 'DEFAULT') return -1
    return a.localeCompare(b)
  })

  // Create a new object with sorted keys
  const sortedGroupedItems: Record<string, T[]> = {}
  sortedKeys.forEach((key) => {
    sortedGroupedItems[key] = groupedItems[key]
  })

  return sorted ? sortedGroupedItems : groupedItems
}

export async function resolveMetadata(
  parent: ResolvingMetadata,
  data: SeoResponseType['seo'],
) {
  const previousTitle = (await parent).title
  const previousDescription = (await parent).description
  const previousImages = (await parent).openGraph?.images || []

  const title = data?.title ?? previousTitle

  return {
    title: title ? `${title} | Auðkenni` : 'Auðkenni',
    description: data?.description ?? previousDescription,
    openGraph: {
      title: data?.title ?? previousTitle ?? 'Auðkenni',
      description: data?.description ?? previousDescription ?? '',
      images: [data?.imageUrl ?? '', ...previousImages],
    },
  }
}

export const pluralize = (count: number, singular: string, plural: string) =>
  count === 1 ? singular : plural

// utils.ts
export const isServer = () => {
  try {
    // Attempt to access a server-only global, like process or window, to detect the environment
    return typeof window === 'undefined'
  } catch {
    return true
  }
}

export const isClient = () => {
  return !isServer()
}

export type GetEmbedOptions = {
  autoplay?: boolean
  muted?: boolean
  loop?: boolean
  controls?: boolean
}

const embedOptions = {
  autoplay: false,
  controls: false,
  loop: false,
  muted: true,
}

export const getEmbedUrl = (
  url: string,
  options: GetEmbedOptions = embedOptions,
) => {
  const opts = { ...embedOptions, ...options }

  const youtubeRegex = RegExp(
    /(https?:\/\/)?(((m|www)\.)?(youtube(-nocookie)?|youtube.googleapis)\.com.*(v\/|v=|vi=|vi\/|e\/|embed\/|user\/.*\/u\/\d+\/)|youtu\.be\/)([_0-9a-z-]+)/i,
  )

  const vimeoRegex = RegExp(
    /(http|https)?:\/\/(www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^/]*)\/videos\/|video\/|)(\d+)(?:\?h=([a-zA-Z0-9]+))?/,
  )

  const youtubeId = (url ?? '').match(youtubeRegex)?.[8]
  const vimeoId = (url ?? '').match(vimeoRegex)?.[4]

  // The hash value in the query string h=<hash>
  const vimeoHash = (url ?? '').match(vimeoRegex)?.[5]

  let embedSrc = ''

  let params = ''

  opts.loop && (params += '&loop=1')
  opts.autoplay && (params += '&autoplay=1')
  opts.controls && (params += '&controls=1')
  opts.muted && (params += '&muted=1')

  if (youtubeId) {
    embedSrc = `https://www.youtube.com/embed/${youtubeId}?rel=0&showinfo=0${params}`
  } else if (vimeoId) {
    embedSrc = `https://player.vimeo.com/video/${vimeoId}?rel=0&showinfo=0${params}${
      vimeoHash ? `&h=${vimeoHash}` : ''
    }`
  }

  return embedSrc
}

export const getHtmlText = (html: string): string => {
  const div = document.createElement('div')
  div.innerHTML = html
  return div.textContent || div.innerText || ''
}

export const containsLongWord = (input: string, max = 22): boolean => {
  const words = input.split(/\s+/)
  for (const word of words) {
    if (word.length > max) {
      return true
    }
  }
  return false
}

export function handleTranslatableStrings(
  locale: string,
  translatableString?: TranslatableString,
) {
  if (!translatableString) return ''

  if (locale === 'is') {
    return translatableString.is ?? ''
  } else if (locale === 'en') {
    return translatableString.en ?? translatableString.is ?? ''
  }
}

export function i18nArrStrHandler(
  locale: string,
  arrayString?: InternationalizedArrayString,
) {
  return arrayString?.find((item) => item._key === locale)?.value ?? ''
}
