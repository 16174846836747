import { createClient } from 'next-sanity'

import { apiVersion, dataset, projectId, useCdn } from '../env'

const config = {
  apiVersion,
  dataset,
  projectId,
  useCdn,
  token: process.env.SANITY_API_TOKEN,
}

const productionClient = createClient({
  ...config,
  perspective: 'published',
})

const previewClient = createClient({
  ...config,
  perspective: 'previewDrafts',
})

export const client = (preview?: boolean) => {
  return preview ? previewClient : productionClient
}
