'use client'

import NextImage, { ImageProps } from 'next/image'
import { useNextSanityImage } from 'next-sanity-image'
import { ReactElement } from 'react'

import { client } from '@/sanity/lib/client'
import { ImageType } from '@/types/general'

export type CustomImageProps = ImageType & { nextImageProps?: ImageProps }

export const CustomImage = ({
  sanityImageData,
  nextImageProps,
  ...props
}: CustomImageProps): ReactElement | null => {
  const imageProps = useNextSanityImage(
    client(),
    sanityImageData ? sanityImageData.file?.asset : null,
    {
      imageBuilder: (imageUrlBuilder) =>
        imageUrlBuilder.maxWidth(1400).auto('format').quality(80).fit('max'),
    },
  )

  let customImageProps = {}

  const isSanityImage = sanityImageData && imageProps

  if (sanityImageData && imageProps) {
    customImageProps = {
      // this loader may not be needed for fill images?
      // loader: imageProps.loader,
      placeholder: 'blur',
      blurDataURL: sanityImageData.file.asset.metadata.lqip,
      // hotspot positioning might be happening automatically, not sure...
      // objectPosition: getPositionFromHotspot(undefined),
    }
  }
  return (
    <NextImage
      fill
      {...(sanityImageData && { ...customImageProps })}
      {...(!sanityImageData && { src: props.src, alt: props.alt })}
      src={isSanityImage ? imageProps?.src : props?.src ?? ''}
      alt={(isSanityImage ? sanityImageData?.alt : props?.alt ?? '') ?? ''}
      className="object-cover"
      {...nextImageProps}
    />
  )
}

export default CustomImage
